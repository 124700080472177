<template>
    <div class="w-100">
        <div class="p-3">
            <h1 style="opacity: 0.7; font-size: 1.25rem;" class="generic-typography">{{ $gettext("Change_your_language") }}
            </h1>
        </div>
        <div>
            <b-form class="customized-form w-100">
                <ValidationObserver ref="changelang" tag="div">

                    <div class="col-md-6">

                        <label class="zc-form-label" for=""> {{ $gettext("Select_Language") }}</label>
                        <CustomDropdown :selectedOption="language" :options="languageOptions" label="label"
                            @update:selectedOption="updateSelectedLanguage"></CustomDropdown>


                    </div>

                    <div class="col-12 my-3 btn-flex-fd-row-jc-sb">
                        <!-- <span @click="goBack" class="go-back">Back</span> -->
                        <b-button @click.prevent.stop="ValidateInput" variant="primary" size="sm">{{ $gettext("Save_change")
                        }}</b-button>

                    </div>
                </ValidationObserver>
            </b-form>
        </div>
    </div>
</template>

<script>
import CustomDropdown from '../../../components/Dropdown/Dropdown.vue'
export default {
    name: "changelang",
    components: { CustomDropdown },
    data() {
        return {
            // languageOptions: [
            //     "French",
            //     "English",
            //     "Spanish",
            //     "Arabic",
            //     "German",
            //     "Portuguese",
            //     "Japanese",
            //     "Russian",
            //     "Italian",
            // ],
            // language: 'English',
            language: { "en": "English", label: "English", value: 'en' },
            languageOptions: [{ "en": "English", label: "English", value: 'en' }, { "sw": "Swahili", label: "Swahili", value: 'sw' }],
            dropdownOption: false,
        }
    },

    methods: {
        updateSelectedLanguage(language) {
            this.language = language;
            console.log(language);
            this.$language.current = language.value;
            this.$store.dispatch("onSetSelectedLang", language.value);

        },
        ValidateInput() {

            console.log(this.language);
            localStorage.setItem("currentLang", JSON.stringify(this.language))
            this.$language.current = this.language.value;
            this.$store.dispatch("onSetSelectedLang", this.language.value);
            this.$toasted.show(`Langauge changed successfully!`, {
                duration: 6000,
                type: "success",
                icon: "check",
            });
        },
    },
    beforeMount() {
        const CurrentLang = JSON.parse(localStorage.getItem('currentLang'))
        console.log("lang>>>>>", CurrentLang);
        this.language = CurrentLang !== null && CurrentLang !== undefined ? CurrentLang : { "en": "English", label: "English", value: 'en' }
        this.$language.current = CurrentLang !== null && CurrentLang !== undefined ? CurrentLang?.value : 'en'
    },


}
</script>

<style></style>